@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Akrobit";
  src: url(./fonts/Akrobat-Regular.otf) format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Akrobit";
  src: url(./fonts/Akrobat-Bold.otf) format("opentype");
  font-weight: bold;
}
